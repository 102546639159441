@import "https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css";

html{
   background: linear-gradient(45deg, rgb(19, 73, 95), rgb(118, 75, 226), rgb(131, 115, 198), rgb(119, 211, 185)) fixed;
   background-size: 400%;
  color: white;
  animation: background-animated 10s ease infinite;
  cursor: url(mouse1.png), auto;
}

@keyframes background-animated {
  0% { background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

button.bt {
  background: transparent;
  color: white;
  padding: 1em;
  border: 0.2em solid white;
  border-radius: 50%;
  font-weight: bolder;
  display: block;
  margin: auto;
  cursor: url(mouse1.png), auto;
}
button{

}

button.btn {
  background: rgb(118, 75, 226);
  color: white;
  padding: 1em;
  border: 0.2em solid white;
  border-radius: 10px;
  font-weight: bolder;
}

button.bt:hover{
  background: white;
  color: teal;
}

div.bt-container{
    align-self: center;
}

.main{
  display: grid;
  grid-template-rows: auto auto;
}
.modal-wrapper {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 2em;
  border: 1px solid #888;
  width: 80%;
  color: black;
}

.right-corner {
  position: fixed;
  right: 2%;
  top: 5%;
}

.pad{
  padding: 0.75em;
}

@media (max-width: 700px){
.bt-container{
  display: none;
}
}
