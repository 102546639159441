@import url(https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css);
html{
   background: linear-gradient(45deg, rgb(19, 73, 95), rgb(118, 75, 226), rgb(131, 115, 198), rgb(119, 211, 185)) fixed;
   background-size: 400%;
  color: white;
  -webkit-animation: background-animated 10s ease infinite;
          animation: background-animated 10s ease infinite;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABF1BMVEX///8PgN4PgeAXi+gAmcwQgeEQgeAPgeEVgOoQguAQgeARgd8UidgQgeAQgeARgeASgNsPgOEQgeAQgeARiN0VgN8QgeAQgeEQgN8QgeAQgeAQgN8Ph+ETgN8QgeAQgeAQgOAOgOMQgt8QgeAPgOANhuQRguEQgeANgOYPgeAQgeARgN8MhtsQgN8QgeARgOAMgNwAgP8QgeAQgOAPgeEQgeAQgOEUheAAktsQgeAPgeAQgN8UgOIRguAPgeARgeEThOMQgeAQgeAQgeAQgd8QgOAPguERgN0QgeAPguEQgeARgOIQgt4AgNURguEPgOEQgeAQgeARgd8Pgd8Aqv8QgeAQguEQguAQgeARgeEOg+MSgOEQgeC1dCQHAAAAXHRSTlMARnULBeLqdwxy63kN7+17DofufQ8Y+H8QnPCBESj+8YMSsfKFEzvzFMb0iRVQ9YsWAtmNZfaPGQfo95Eaete4G+zNrnFSMx77VqQsLwa5RKzOWVcD326tgpglKkE8uJsAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAD5SURBVDjLhdJpXwFRGMbhU5NWS5QWVKJMiLSNolQILbSIkvv7f44600zDzDyP/8tzX2+e34yYmlYE24xndm6eFQvA4pKXAV4fAH9gmRZByEIrqxQIr+kCvvUNQmzCKBKNuYKtbVNgJ77rJhKwSu7tO0EKo6nBA4dIjwlksoc2kIOt/FFhDByfwEFOw6PiDM7OtaIFLi5dBErlq39x7bLnKzflWxPEVGu4q9a0+n3DdqxmzhrxAzVbBnigPuqjAZ4o8Py3t9oUEB0dVMhdvOjglQbiTYIoA94l6DLgo8cdIeuzR/ym+LkjZHF88qBQ+uKBGHQngO+h/eUHx5llypMclsgAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDMtMTRUMTg6NDE6MzEtMDU6MDB1uym9AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAzLTE0VDE4OjQxOjMxLTA1OjAwBOaRAQAAAABJRU5ErkJggg==), auto;
}

@-webkit-keyframes background-animated {
  0% { background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

@keyframes background-animated {
  0% { background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

button.bt {
  background: transparent;
  color: white;
  padding: 1em;
  border: 0.2em solid white;
  border-radius: 50%;
  font-weight: bolder;
  display: block;
  margin: auto;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABF1BMVEX///8PgN4PgeAXi+gAmcwQgeEQgeAPgeEVgOoQguAQgeARgd8UidgQgeAQgeARgeASgNsPgOEQgeAQgeARiN0VgN8QgeAQgeEQgN8QgeAQgeAQgN8Ph+ETgN8QgeAQgeAQgOAOgOMQgt8QgeAPgOANhuQRguEQgeANgOYPgeAQgeARgN8MhtsQgN8QgeARgOAMgNwAgP8QgeAQgOAPgeEQgeAQgOEUheAAktsQgeAPgeAQgN8UgOIRguAPgeARgeEThOMQgeAQgeAQgeAQgd8QgOAPguERgN0QgeAPguEQgeARgOIQgt4AgNURguEPgOEQgeAQgeARgd8Pgd8Aqv8QgeAQguEQguAQgeARgeEOg+MSgOEQgeC1dCQHAAAAXHRSTlMARnULBeLqdwxy63kN7+17DofufQ8Y+H8QnPCBESj+8YMSsfKFEzvzFMb0iRVQ9YsWAtmNZfaPGQfo95Eaete4G+zNrnFSMx77VqQsLwa5RKzOWVcD326tgpglKkE8uJsAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAD5SURBVDjLhdJpXwFRGMbhU5NWS5QWVKJMiLSNolQILbSIkvv7f44600zDzDyP/8tzX2+e34yYmlYE24xndm6eFQvA4pKXAV4fAH9gmRZByEIrqxQIr+kCvvUNQmzCKBKNuYKtbVNgJ77rJhKwSu7tO0EKo6nBA4dIjwlksoc2kIOt/FFhDByfwEFOw6PiDM7OtaIFLi5dBErlq39x7bLnKzflWxPEVGu4q9a0+n3DdqxmzhrxAzVbBnigPuqjAZ4o8Py3t9oUEB0dVMhdvOjglQbiTYIoA94l6DLgo8cdIeuzR/ym+LkjZHF88qBQ+uKBGHQngO+h/eUHx5llypMclsgAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDMtMTRUMTg6NDE6MzEtMDU6MDB1uym9AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAzLTE0VDE4OjQxOjMxLTA1OjAwBOaRAQAAAABJRU5ErkJggg==), auto;
}
button{

}

button.btn {
  background: rgb(118, 75, 226);
  color: white;
  padding: 1em;
  border: 0.2em solid white;
  border-radius: 10px;
  font-weight: bolder;
}

button.bt:hover{
  background: white;
  color: teal;
}

div.bt-container{
    -webkit-align-self: center;
            align-self: center;
}

.main{
  display: grid;
  grid-template-rows: auto auto;
}
.modal-wrapper {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 2em;
  border: 1px solid #888;
  width: 80%;
  color: black;
}

.right-corner {
  position: fixed;
  right: 2%;
  top: 5%;
}

.pad{
  padding: 0.75em;
}

@media (max-width: 700px){
.bt-container{
  display: none;
}
}

